@import '../../../palette.scss';
@import '../../../fonts.scss';
@import '../../../mixins';
  
.button {

  @include margin();
  text-align: center;
  font-family: $inter;
  padding: 10px 25px;
  border-radius: 7px;
  border: none;
  text-decoration: none;
  font-weight: 700;
  
  
  &.button-blue {
    background-color: $primary;
    color: $secondary;
    &:hover {
      zoom: 1.1;
    }
  }
  &.button-rock {
    background: linear-gradient(to right, #5DA143, #1F41C1, #FF6666);
    color: $nav-link-color;
    &:hover {
      zoom: 1.1;
    }
  }
  &.button-yellow {
    background-color: $headers-color;
    color: $nav-link-color;
    border: 1px solid $headers-color;
    &:hover {
      zoom: 1.1;
    }
  }
  &.button-grey {
    background-color: $yellow;
    color: $secondary;
    &:hover {
      zoom: 1.1;
    }
  }
  &.button-black {
    background-color: $secondary;
    color: $nav-link-color;
    &:hover {
      zoom: 1.1;
    }
  }
}