@import '../../palette.scss';
@import '../../fonts.scss';
@import '../../mixins.scss';
.header {
   
    height: 100vh;
    width: 100vw;
    background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url(../../../public/main.jpg);
    background-size: cover;
    text-align: center;
    border-bottom: 15px solid $nav-link-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    &__info {
        @include section_padding();
    }
    & h1 {
        color: $secondary;
        font-family: $montserrat;
        font-size: 80px;
        padding-bottom: 6px;
        font-weight: 500;
        text-shadow: -2px 0 $yellow, 0 2px $yellow, 2px 0 $yellow, 0 -2px $yellow;
    }
    & p {
        font-size: 30px;
        color: $secondary;
        font-family: $inter;
        padding-bottom: 10px;
        font-weight: 400;
    }
    &__links {

        text-align: center;
        margin-top: 10px;
        text-transform: uppercase;
    }
    & a:not(:last-child) {
        margin-right: 10px;
    }
    & a {
        margin-bottom: 10px;
    }
}

@media (max-width: 576px){
    .header {
        height: auto;
        width: 100%;
        & h1 {
            font-size: 60px;
        }
        & a {
            display: block;
            width: 100%;
        }
        &__info {
            padding: 64px 15px;
        }
    }
}