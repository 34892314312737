@import '../../../palette.scss';
@import '../../../fonts.scss';
@import '../../../mixins.scss';
@import '../../../variables.scss';

.header-form {
    height: 100vh;
    padding: 30px 64px;
    &__info {
        @include flex-justify-wrap(space-between, center, wrap);
        border: 5px solid $yellow;
        background: $secondary;
        width: 98%;
        height: 100%;
        border-radius: 8px;
        padding: 45px;
        &--logo {
            width: 48%;
            
        }

        &--form {
            width: 40%;
            & h1 {
                font-family: $montserrat;
                color: $nav-link-color;
                font-weight: 700;
                font-size: 44px;
                margin-bottom: 30px;
            }
            & input[type=submit] {
                width: 100%;
            }
        }
    }
}

@media (max-width: 992px) {
    .header-form {
        padding: 10px;
        height: 100%;
        
        
        &__info {
            padding: 20px;
            
            &--logo,
            &--form {
                width: 100%;
            }
            &--logo {
                display: none;
            }
        }
    }
}