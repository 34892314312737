@import '../../../../palette.scss';
@import '../../../../fonts.scss';
@import '../../../../variables.scss';

.whatIdo-card {
    width: 23%;
    background-color: #5F5F58;
    border-radius: 8px;
    padding: 15px;
    margin-top: 15px;
    &__icon {
      color: $yellow;
      font-size: 20px;
    }

    &__title {
      color: $nav-link-color;
      font-family: $montserrat;
      font-weight: 700;
    }

    &__description {
      color: $nav-link-color;
      font-family: $inter;
      margin-bottom: 20px;
    }
    &:hover {
      border: 5px solid $yellow;
    }
    & a {
      font-family: $montserrat;
      font-size: 19px;
      color: $yellow;
      text-decoration: none;
      &:hover {
        text-decoration: double;
      }
    }
}

@media (min-width: 576px) and (max-width: 992px) {
  .whatIdo-card {
    margin-bottom: 20px;
  }
}

@media (max-width: 992px){
  .whatIdo-card {
    width: 48%;
  }
}

@media (max-width: 576px){
  .whatIdo-card {
    width: 98%;
  }
}