
@import '../../../../palette.scss';
@import '../../../../fonts.scss';

.review {
    font-family: $montserrat;
    &__author {
        color: $headers-color;
        font-size: 22px;
        font-weight: 700px;
    }
    &__stars {
        margin: 10px 0;
        & svg {
            font-size: 23px;
        }
    }
    &__text {
        font-size: 19px;
        color: $headers-color;
    }
    
    &__date {
        font-size: 17px;
        font-weight: 200;
        color: $nav-link-color;
    }
}
