@import '../../../../palette.scss';
@import '../../../../fonts.scss';

.order-card {
    width: 33%;
    background-color: $secondary;
    border: 1px solid $nav-link-color;
    border-radius: 8px;
    &--info {
        padding: 15px;
    }
    &__title {
        font-family: $poppins;
        color: $yellow;
        font-weight: 700;
        font-size: 27px;
        margin-bottom: 15px;
    }
    &__description {
        color: $nav-link-color;
        font-size: 20px;
        font-family: $inter;
    }
}

@media (max-width: 576px) {
    .order-card{
        width: 100%;
    }
}