@import '../../../palette.scss';
@import '../../../fonts.scss';
@import '../../../mixins.scss';

.website-orders {
    @include section_padding();
    & h2 {
        color: $headers-color;
        font-family: $inter;
        font-size: 35px;
        font-family: 500;
        
    }
    & h1 {
        color: $secondary;
        font-family: $poppins;
        font-size: 75px;
        font-weight: 700;
        text-shadow: 7-1px 0 $yellow, 0 1px $yellow, 1px 0 $yellow, 0 -1px $yellow;
        margin: 60px 0;
    }
    & ul {
        list-style-type: none;
        padding-inline-start: 0;
    }
    & strong p {
        margin: 25px 0;
    }
}

@media (max-width: 572px) {
    .website-orders {
        & h1 {
            font-size: 55px;
        }
        &__buttons a{
            display: block;
            margin-top: 15px;
        }
    }
}