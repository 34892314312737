@import '../../../../palette.scss';
@import '../../../../fonts.scss';
@import '../../../../mixins.scss';
@import '../../../../variables.scss';



.project-show {
    background-color: $secondary;
    border-top: 1px solid $yellow;
    @include flex-justify-wrap(space-between, none, wrap);
    @include section_padding();
    &--photo,
    &--informations,
    &--gallery,
    &--reviews {
        width: 48%;
        margin-bottom: 20px;
    }

    &--reviews {
        & h4 {
            margin-bottom: 30px;
        }
        & div {
            margin-top: 27px;
        }
    }
    &__img {
        width: 100%;
    }
    & h3 {
        font-size: 30px;
        color: $yellow;
        font-family: $montserrat;
        font-weight: 700;
    }
    & h4 {
        color: $nav-link-color;
        font-family: $poppins;
    }
    & p {
        color: $nav-link-color;
    }
    &__additionalInfos {
        font-size: 18px;
        color: $nav-link-color;
        font-family: $inter;
        font-weight: 500;
        & div {
            width: 100%;
            margin-bottom: 10px;
        }
        & div i {
            color: $yellow;
        }
    }

}

.pictures {
    @include flex-justify-wrap(space-between, none, wrap);
    & img {
        width: 48%;
        margin: 15px 0;
    }
}

.description li a {
    color: $yellow;
    text-decoration: none;
}

@media (max-width: 572px) {
    .project-show {
        &--photo,
        &--informations,
        &--gallery,
        &--reviews {
            width: 100%;
        }
    }
}
