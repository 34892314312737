@import '../../palette.scss';
@import '../../fonts.scss';
@import '../../mixins.scss';

.footer {
    background: $headers-color;
    @include section_padding();
    font-family: $inter;
    color: $nav-link-color;
    flex-shrink: 0;
    
    & h2 {
        color: $nav-link-color;
        margin-bottom: 6px;
    }

    &__icons {
        margin-bottom: 10px;
        & a:not(:last-child) {
            margin-right: 8px;
        }
    }

    &__copyright {
        font-size: 13px;
    }
    &__panel {
        text-decoration: none;
        color: $nav-link-color;
        &:hover {
            color: $nav-link-color;
        }
    }
}