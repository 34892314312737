@import '../../../palette';

.icon {
    font-size: 30px;
    color: $nav-link-color;
    margin-top: 30px;
}

.icon-project {
    font-size: 18px;
    margin-right: 7px;
}