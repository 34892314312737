@import '_palette.scss';
@import '_mixins.scss';

html, body {
  height: 100%;
}

body {
  margin: 0;
  background: $nav-link-color;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  flex: 1 0 auto;
}

.about,
.achievements {
  @include section_padding();
}

.achievement-div {
  @include flex-justify-wrap(space-between, center, wrap)
}