@import '../../../../palette';

.advantage {
    margin-bottom: 25px;
    &::before {
        content: '✓';
        font-size: 22px;
        font-weight: 700;
        display: inline;
        color: $headers-color;
        margin-right: 10px;
    }
}