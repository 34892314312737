@import '../../palette.scss';
@import '../../fonts.scss';
@import '../../mixins.scss';

nav {
  @include flex-justify-wrap(space-between, baseline, nowrap);
  padding: 20px 64px;
  background: $secondary;
  & button {
    background: none;
  }
  & .nav-links {
    list-style-type: none;
    & li {
      display: inline-block;
    }
    & .nav-link {
      font-family: $inter;
      text-transform: uppercase;
      border: none;
      color: $nav-link-color;
      margin-left: 5px;
      letter-spacing: 0.2em;
      &:hover {
        font-weight: 700;
        color: $headers-color;
        
      }
      &.active {
        font-weight: 700;
        color: $secondary;
        background-color: $yellow;
        border-radius: 5px;
      }
    }
  }
  & #nav-icon4 {
    display: none;
    width: 60px;
    height: 15px;
    position: absolute;
    right: 15px;
    top:13px;
    &.open {
      & span:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: -3px;
        left: 8px;
      }
      & span:nth-child(2) {
        width: 0%;
        opacity: 0;
      }
      & span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 39px;
        left: 8px;
      }
    }
    & span {
      display: block;
      position: absolute;
      height: 8px;
      width: 100%;
      background: $nav-link-color;
      border-radius: 9px;
      opacity: 1;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }
    & span:nth-child(1) {
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    & span:nth-child(2) {
      top: 18px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    & span:nth-child(3) {
      top: 36px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
  }
}
  

#logo {
	height: 58px;
}
#mainNav {
    background: $secondary;

}

@media (max-width: 992px) {
  nav {
    display: block;
    position: relative;
    overflow: hidden;
    padding: 20px 10px;
    & .nav-links {
      display: none;
      padding-inline-start: 0;
      & li {
        display: block;
        margin-top: 15px;
      }
    }
    & #nav-icon4 {
      display: block;
      margin-top: 10px;
    }
  }
  #logo {
    display: block;
    width: 75%;
  }
}