@import '../../../palette.scss';
@import '../../../fonts.scss';

.project-card {
    width: 23%;
    background-color: $secondary;
    border-radius: 8px;
    animation: appear .5s ease;
    &__img {
        width: 100%;
    }
    &--body {
        padding: 15px;
    }
    &__title {
        font-family: $montserrat;
        font-size: 28px;
        font-weight: 700;
        
        & a {
            text-decoration: none;
            color: $yellow;
        }
        & a:hover {
            color: $headers-color;
        }
    }
    & p {
      color: $nav-link-color;
    }
    &__additionalInfos {
        font-family: $inter;
        margin: 10px 0;
    }
    &:hover {
      transform: scale(1.07);
      border: 1px solid $yellow;
      overflow: hidden;
    }
}


  @media (max-width: 992px){
    .project-card {
      width: 47%;
    }
  }
  
  @media (max-width: 576px){
    .project-card {
      width: 98%;
    }
  }
