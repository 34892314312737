@import '../../../mixins.scss';
@import '../../../palette.scss';


.loader-container {
    @include flex-justify-wrap(center, center, none);
    width: 100%;
    justify-self: center;

    &__loader {
        border: 16px solid $secondary;
        border-radius: 50%;
        border-top: 16px solid $nav-link-color;
        width: 120px;
        height: 120px;
        animation: spin 2s linear infinite;
        margin: 120px 0;
    }

      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
}