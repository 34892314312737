@import '../../../mixins.scss';
@import '../../../palette.scss';

.projects h1 {
    padding: 64px 64px 30px;
}
.projects-info {
    background: $secondary;
    @include section_padding();
}
.projects-cards {
    @include flex-justify-wrap(flex-start, none, wrap);
    gap: 30px;
}


@media (max-width: 992px) {
    .projects h1 {
        padding: 64px 15px;
    }
}