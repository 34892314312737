@import '../../../mixins.scss';

.orders {
    @include section_padding();
}

.orders-cards {
    @include flex-justify-wrap(space-between, center, wrap);
}

